function validate(form) {
    var required = $('*[required]', form),
        message = $('.success-message', form.parent()),
        preloader = $('.preloader', form.parent());
    required.each(function () {
        if ($(this).val() == '') {
            $(this).addClass('red').removeClass('green');
        }
        else {
            $(this).addClass('green').removeClass('red');
        }
    });

    if ($('*[required].red', form).length == 0) {
        form.fadeTo(300, 0.0, function () {
            preloader.fadeIn(function () {
                $.ajax({
                    method: "POST",
                    url: "/send.php",
                    data: form.serialize(),
                    success: function (html) {
                        setTimeout(function () {
                            preloader.fadeOut(function () {
                                form.slideUp();
                                message.slideDown(function () {
                                    message.fadeTo(300,1.0);
                                })
                            });
                        }, 500);
                    }
                })
            });

        });
    }
}

$(document).ready(function () {
    $('form *[required]').on('change', function () {
        if ($(this).val() == '') {
            $(this).addClass('red').removeClass('green');
        }
        else {
            $(this).addClass('green').removeClass('red');
        }
    })

    $('#callback .btn').click(function () {
        validate($(this).closest('form'));
    })
});